<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="edit = true"
        >編集</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <template>
        <v-text-field
          v-model="kbn"
          label="区分"
          prepend-icon="mdi-text"
          outlined
          maxlength="6"
          :rules="[Rules.Required]"
        ></v-text-field>
        <v-text-field
          v-model="name"
          label="名称"
          prepend-icon="mdi-text"
          outlined
          maxlength="50"
          :rules="[Rules.Required]"
        ></v-text-field>
      </template>

      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import CodeGroup from "@/models/CodeGroup";

export default {
  name: "CodeGroupDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      kbn: "",
      name: "",
      originkey: ""
    };
  },
  computed: {
    Title() {
      return (
        this.title + " " + (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    }
  },
  methods: {
    async postRecord(param) {
      await this.$post(this.Paths.codeGroup, param);
      this.add = false;
    },
    async putRecord(param) {
      await this.$put(this.Paths.codeGroup, param);
    },
    complete() {
      console.log("complete", this.kbn);
      if (!this.$refs.form.validate()) return;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit");
      this.$loading();
      try {
        const param = new CodeGroup(this.kbn, this.name, this.originkey);

        console.log("submit_param", param);
        if (this.add) {
          await this.postRecord(param);
        } else {
          await this.putRecord(param);
        }
        this.$info("更新しました。", "コードグループ");
        this.edit = false;
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    back() {
      const path = "/list/code-group";
      this.$router.push(path);
    }
  },
  async created() {
    console.log(this.name, "created");
    this.params = this.$route.params;

    this.$loading();
    try {
      if (this.prosess && typeof this.prosess === "string")
        this.prosess = this.prosess.toLowerCase();

      switch (this.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      if (!this.add) {
        this.kbn = this.args.kbn;
        this.name = this.args.name;
        this.originkey = this.kbn;
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style></style>
