export default class CodeGroup {
  kbn: string;
  name: string;
  originkey: string;
  constructor(kbn = "", name = "", originkey = "") {
    this.kbn = kbn;
    this.name = name;
    this.originkey = originkey;
  }
}
